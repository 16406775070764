import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export const TechnicalSpecs = () => {
    const [isOpen, setIsOpen] = useState([false, false, false, false]); // State to track open/close status

    const toggleCollapse = (index) => {
        const newIsOpen = [...isOpen];
        newIsOpen[index] = !newIsOpen[index];
        setIsOpen(newIsOpen);
    };

    const itemNames = ["Product Catalogue", "User Manual", "User Guide", "Annexure"];

    return (
        <React.Fragment>
            <div className="col-lg-6 tech-specs d-flex flex-column">
                <div className="px-4 text-center">
                    <h3 className="fs-2 underline underline-heading texts">Technical Specifications</h3>
                    <div className="d-flex flex-column pt-3">
                        {[0, 1, 2, 3].map((index) => (
                            <div key={index} className={`mt-2 bg-dark text-white border rounded`}>
                                <div className="d-flex justify-content-between align-items-center" style={{ fontSize: '18px', height: "40px" }}>
                                    <span style={{ padding: '15px' }}>{itemNames[index]}</span>
                                    <FontAwesomeIcon
                                        icon={isOpen[index] ? faAngleUp : faAngleDown}
                                        style={{ cursor: 'pointer', padding: '15px' }}
                                        onClick={() => toggleCollapse(index)}
                                    />
                                </div>
                                {isOpen[index] && (
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        {/* Content for the collapsible section */}
                                        <p className="text-start">This is the content of {itemNames[index]}.</p>
                                        <button className="btn btn-dark" style={{ padding: '10px', marginLeft: '10px' }}>
                                            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
                                            Download
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
