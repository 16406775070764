import React from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";

export const CommentForm = () => {
    return (
        <div className="comment-form-wrap pt-5 border-top">
            <div className="container">
                <div className="text-center">
                    <h2 className="fs-3 fw-medium underline underline-heading texts text-justify">Write a Comment</h2>
                </div>
                <div className="row mt-3 mt-lg-5">
                    <div className="col-lg-6">
                        <div className="comment-main pe-2">
                            <div class="d-block d-md-flex comment-box pb-3 mb-4 border-bottom">
                                <div class="comment-avatar mx-auto me-md-3 mb-md-0 mb-3">
                                    <img src={Service1} alt="author image" />			
                                </div>
                                <div class="comment-content">
                                    <div class="d-block d-md-flex text-center text-md-start align-items-center justify-content-between">
                                        <h5 class="mb-0 me-0 me-md-3 text-black">Bella Doe</h5>
                                        <span>7 years ago</span>
                                    </div>
                                    <div class="text mt-1">
                                        <p class="mb-0">As a small business owner, I've often struggled with creating a comprehensive market plan. This blog post has provided me with a clear framework to get started. Thanks for breaking down the components of market planning so succinctly.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block d-md-flex comment-box pb-3 mb-4 border-bottom">
                                <div class="comment-avatar mx-auto me-md-3 mb-md-0 mb-3">
                                    <img src={Service1} alt="author image" />			
                                </div>
                                <div class="comment-content">
                                    <div class="d-block d-md-flex text-center text-md-start align-items-center justify-content-between">
                                        <h5 class="mb-0 me-0 me-md-3 text-black">Bella Doe</h5>
                                        <span>7 years ago</span>
                                    </div>
                                    <div class="text mt-1">
                                        <p class="mb-0">As a small business owner, I've often struggled with creating a comprehensive market plan. This blog post has provided me with a clear framework to get started. Thanks for breaking down the components of market planning so succinctly.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block d-md-flex comment-box pb-3 mb-4 border-bottom">
                                <div class="comment-avatar mx-auto me-md-3 mb-md-0 mb-3">
                                    <img src={Service1} alt="author image" />			
                                </div>
                                <div class="comment-content">
                                    <div class="d-block d-md-flex text-center text-md-start align-items-center justify-content-between">
                                        <h5 class="mb-0 me-0 me-md-3 text-black">Bella Doe</h5>
                                        <span>7 years ago</span>
                                    </div>
                                    <div class="text mt-1">
                                        <p class="mb-0">As a small business owner, I've often struggled with creating a comprehensive market plan. This blog post has provided me with a clear framework to get started. Thanks for breaking down the components of market planning so succinctly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <Form>
                            <Row className="mb-3">
                                <Col md>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Username"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" placeholder="Enter Username" />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <Form.Control type="email" placeholder="name@example.com" />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '140px' }}
                                />
                            </FloatingLabel>

                            <Button variant="primary" type="submit" className="brand-button">
                                Submit <FontAwesomeIcon icon={faCircleArrowRight} className="ms-2" />
                            </Button>
                        </Form>
                    </div>
                </div>
                
            </div>
        </div>
        
    )
}