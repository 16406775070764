import React, { useEffect } from "react";
// import image2 from "../../assets/images/hero_page_home_right.png";
import arrowRightIcon from "../../assets/images/img_.svg";
import iotModuleImage from "../../assets/images/IOT-icon.png";
import sensorModuleImage from "../../assets/images/SensorModuleIcon.png";
import miliMachinaryImage from "../../assets/images/MillingMachineIcon.png";
import intrafcingModuleImage from "../../assets/images/standard-interfaceIcon.png";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "./video";
import { Link } from "react-router-dom";

// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useGSAP } from '@gsap/react';
// import { useRef } from 'react';

// gsap.registerPlugin(useGSAP, ScrollTrigger);

export const Hero = () => {
  // const main = useRef();

  // useGSAP(
  //   () => {
  //     const boxes = gsap.utils.toArray('.box');
  //     boxes.forEach((box) => {
  //       gsap.to(box, {
  //         x: 50,
  //         scrollTrigger: {
  //           trigger: box,
  //           start: 'bottom bottom',
  //           end: 'top 0%',
  //           scrub: true,
  //         },
  //       });
  //     });
  //   },
  //   { scope: main }
  // );

  const navigate = useNavigate();
  const text = "TECHNICAL SERVICES & SUPPORT"; // Text to be animated
  const delay = 300; // Delay between each letter in milliseconds
  let index = 0;

  useEffect(() => {
    typeText();
  }, []); // Run once after initial render

  function typeText() {
    const heroText = document.getElementById("hero-text");
    if (heroText && index < text.length) {
      heroText.textContent += text[index];
      index++;
      if (index < text.length) {
        setTimeout(typeText, delay);
      }
    }
  }

  // useEffect(() => {
  //   typeText()
  // }, [])
  return (
    <div className="common-hero-style hero-section">
      <div className="position-relative">
        <VideoPlayer />
        <div className="text-div">
          <div className="hero-text-section">
            <h3 className="text-about text-white">
              SMART INNOVATION FOR SMARTER INDIANS
            </h3>
            <h2 className="hero-text shadow-text" id="hero-text"></h2>
            <Link to="#" className=" text-decoration-none pt-1 pb-1">
              <p className="pt-1 pb-1 home-hero-text shadow-text">
                Control Panel Design/Fabrication: <br></br>Industrial Electrical
                | Electronic Enclosures | Customized
                <br />
                PLC / SCADA / HMI Programming Services: Application and Program
                Development.
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="container pt-0 pt-md-5 pb-5 border-bottom">
        <div className="row mx-auto">
          <div
            className="col-md-3"
            onClick={() => navigate("/products/iot-module")}
          >
            <div className="hero-card box">
              <div className="hero-card-content">
                <div class="card">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-4">
                      <img
                        src={iotModuleImage}
                        className="hero-card-img-top img-fluid"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body py-0 px-2">
                        <h5 class="card-title">IOT Module </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-3"
            onClick={() => navigate("/products/sensor-module")}
          >
            <div className="hero-card box">
              <div className="hero-card-content">
                <div class="card">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-4">
                      <img
                        src={sensorModuleImage}
                        className="hero-card-img-top img-fluid"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body py-0 px-2">
                        <h5 class="card-title">Sensor Module </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-3"
            onClick={() => navigate("/under-maintenance")}
          >
            <div className="hero-card box">
              <div className="hero-card-content">
                <div class="card">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-4">
                      <img
                        src={miliMachinaryImage}
                        className="hero-card-img-top img-fluid"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body py-0 px-2">
                        <h5 class="card-title">Mill Machinary Module</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-3"
            onClick={() => navigate("/products/standard-interfacing-module")}
          >
            <div className="hero-card box">
              <div className="hero-card-content">
                <div class="card">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-4">
                      <img
                        src={intrafcingModuleImage}
                        className="hero-card-img-top img-fluid"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body py-0 px-2">
                        <h5 class="card-title">Standard interfacing Module</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="row d-flex gap-0 module-row">
<div className="col-3 cm-3 ">
  <div className="hero-card">
    <div className="hero-card-content">
      <img
        src={iotModuleImage}
        className="hero-card-img-top img-fluid"
        alt="..."
      />
      <div className="hero-card-body">
        <h5 className="hero-card-title">IOT MODULES</h5>
      </div>
    </div>
  </div>
</div>

<div className="col-3 cm-3">
  <div className="hero-card">
    <div className="hero-card-content">
      <img
        src={sensorModuleImage}
        className="hero-card-img-top img-fluid"
        alt="..."
      />
      <div className="hero-card-body">
        <h5 className="hero-card-title">SENSOR MODULES</h5>
      </div>
    </div>
  </div>
</div>

<div className="col-3 cm-3">
  <div className="hero-card">
    <div className="hero-card-content">
      <img
        src={miliMachinaryImage}
        className="hero-card-img-top img-fluid"
        alt="..."
      />
      <div className="hero-card-body">
        <h5 className="hero-card-title">MILL MACHINERY PARTS</h5>
      </div>
    </div>
  </div>
</div>

<div className="col-3 cm-3">
  <div className="hero-card">
    <div className="hero-card-content">
      <img
        src={intrafcingModuleImage}
        className="hero-card-img-top img-fluid"
        alt="..."
      />
      <div className="hero-card-body">
        <h5 className="hero-card-title">STANDARD INTERFACING MODULE</h5>
      </div>
    </div>
  </div>
</div>
</div> */
}
