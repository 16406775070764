import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const [displayText, setDisplayText] = useState("");
  const textOptions = {
    Features:
      "IoT Module offers precise data acquisition through advanced sensors and communication protocols, ensuring seamless connectivity via GPRS and LoRa technology. With versatile integration options like I2C, RS232, and RS485 interfaces, coupled with cloud-based management for centralized data access, users can effortlessly monitor critical parameters such as level, flow, and pump energy consumption. Its intelligent control capabilities enable proactive intervention and automated alerts, empowering businesses to optimize operations and maximize efficiency. Scalable and flexible, IoT Module adapts to various industries, from manufacturing and water management to agriculture and energy, offering tailored solutions for diverse applications.",
    Industries:
      "IoT Module caters to a wide range of industries, including manufacturing, water management, agriculture, energy, and more. Whether monitoring fluid levels in tanks, optimizing energy usage in pumping systems, or enhancing process efficiency in industrial facilities, IoT Module delivers actionable insights for informed decision-making. Its scalable nature allows seamless integration into existing infrastructure, making it an ideal choice for industries seeking to improve reliability, reduce costs, and drive performance through data-driven insights.",
  };

  const changeText = (text) => {
    setDisplayText(textOptions[text]);
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"]);
  }, []);
  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText === textOptions[option]
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }} // Add this style to remove rounded border
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
        </div>
      </div>
    </React.Fragment>
  );
};
