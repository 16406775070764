import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLocationDot,
  faPaperPlane,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/scss/footer.scss";
import footerImage2 from "../assets/images/image_group_footer_2.svg";
import emailIcon from "../assets/images/email_icon_footer.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (subscriptionStatus) {
      setShowToast(true);
      const timeout = setTimeout(() => {
        setShowToast(false);
        setSubscriptionStatus("");
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [subscriptionStatus]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = () => {
    axios
      .post("http://localhost:3001/subscribe", { email })
      .then((response) => {
        setSubscriptionStatus("success");
        setEmail("");
      })
      .catch((error) => {
        setSubscriptionStatus("error");
        console.error("Error subscribing:", error);
      });
  };

  return (
    <React.Fragment>
      <footer className="footer text-white" style={{ width: "100%" }}>
        <div className="container">
          <div className="row card_section align-items-center">
            <div className="col-lg-6">
              <div className="d-flex align-items-center mb-3 mb-lg-0">
                <img
                  src={emailIcon}
                  alt="Icon"
                  className="img-fluid img_media"
                />
                <h1 className="ms-3 pt-3">Stay Ahead with Visves</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input_box"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  className="btn btn-light btn-lg rounded button_function"
                  onClick={handleSubscribe}
                >
                  Subscribe
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#05092b" }}
                    className="icon ps-2"
                  />
                </button>
                {subscriptionStatus === "error" && (
                  <p className="text-danger mt-2">
                    Error subscribing. Please try again later.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="row mx-auto">
              <div className="col-lg-4 col-md-12">
                <div className="footer__content">
                  <Link to="/">
                    <img
                      src={footerImage2}
                      alt="Logo"
                      className="footer__logo img-fluid"
                    />
                  </Link>

                  <p className="footer__desc">
                    We design and engineer custom-automated control solutions
                    that reduce your costs and give your business the
                    competitive edge it needs to succeed.
                  </p>

                  <div className="d-flex ">
                    <Link to="#" className="text-decoration-none">
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>

                    <Link to="#" className="text-decoration-none">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>

                    <Link to="#" className="text-decoration-none">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>

                    <Link to="#" className="text-decoration-none">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="footer__content">
                  <h3 className="footer__title">USEFUL LINKS</h3>

                  <ul className="footer__links list-unstyled">
                    <li>
                      <Link class="nav-link link-light" to="/">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/about">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        About Us
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/products">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Products
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/services">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/news-article">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        News
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/contact-us">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="footer__content">
                  <h3 className="footer__title">PRODUCTS</h3>

                  <ul className="footer__links list-unstyled">
                    <li>
                      <Link
                        class="nav-link link-light"
                        to="/products/iot-module"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        IOT Modules
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/under-maintenance"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Mill Machinery Parts
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/sensor-module"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Sensor Modules
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/standard-interfacing-module"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Standard Interfacing Modules
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="footer__content">
                  <h3 className="footer__title">CONTACT US</h3>

                  <ul className="footer__links list-unstyled">
                    <li class="d-flex">
                      <Link to="#" className="text-decoration-none">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ color: "#351ee0" }}
                          className="me-2 mt-1"
                        />
                      </Link>
                      <Link
                        className="nav-link link-light"
                        to="https://maps.app.goo.gl/Miyqh4sXFDhyFk5r8"
                      >
                        G-39, Safal Sumel Business Park-6, Nr. HanumanPura BRTS
                        Bus Stand, Dudheshawar, Ahmedabad. 380004. (Guj.) India.
                      </Link>
                    </li>

                    <li className="d-flex contact_media">
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "#351ee0" }}
                        className="me-2 mt-1"
                      />

                      <div className="d-flex gap-3 flex-wrap">
                        <Link
                          className="nav-link link-light"
                          to="tel:8000301096"
                        >
                          +91 80003 01096
                        </Link>

                        <Link
                          className="nav-link link-light"
                          to="tel: 8230088089"
                        >
                          +91 82300 88089
                        </Link>
                      </div>
                    </li>

                    <li className="d-flex contact_media">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "#351ee0" }}
                        className="me-2 mt-1"
                      />

                      <div className="d-flex column-gap-3 row-gap-1 flex-wrap">
                        <Link
                          className="nav-link link-light"
                          to="mailto: sales@visves.com"
                        >
                          sales@visves.com
                        </Link>

                        <Link
                          className="nav-link link-light"
                          to="mailto:info@visves.com"
                        >
                          info@visves.com
                        </Link>

                        <Link
                          className="nav-link link-light"
                          to="mailto:support@visves.com"
                        >
                          support@visves.com
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="container line"></hr>

          <div className="row footer-end mb-2">
            <p className="col-lg-8 mb-3 mb-lg-0 text-center text-lg-start">
              <Link
                to="/"
                className="text-white"
                style={{ textDecoration: "none" }}
              >
                {" "}
                © Visves Advance Engineering
              </Link>{" "}
              - 2024. All rights reserved. | Designed and Developed by Qpaix
              Infitech Pvt.Ltd.
            </p>

            <ul className="col-lg-4 list-unstyled d-block d-md-flex flex-row-reverse mb-0 justify-content-center justify-content-lg-start">
              <li>
                <Link
                  className="nav-link link-light px-2 text-center text-md-start"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link link-light px-2 text-center text-md-start"
                  to="under-maintenance"
                >
                  Privacy Policy
                </Link>
              </li>

              {/* <li>
                <Link className="nav-link link-light px-2 text-center text-md-start" to="#">
                  Terms & Condition
                </Link>
              </li> */}
            </ul>
          </div>

          <div
            className={`toast position-fixed top-0 end-0 m-4 ${
              showToast ? "show" : ""
            }`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div
              className="toast-header bg-primary text-white"
              style={{
                display: subscriptionStatus === "success" ? "block" : "none",
              }}
            >
              <strong className="me-auto">Subscription Successful</strong>
              {/* <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button> */}
            </div>
            <div
              className="toast-header bg-danger text-white"
              style={{
                display: subscriptionStatus === "error" ? "block" : "none",
              }}
            >
              <strong className="me-auto">Error Subscribing</strong>
              {/* <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button> */}
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
