import React from "react";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const BlogContent = () => {
  return (
    <div className="blog-content-wrap py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="social-media mb-5">
              <h5 class="mb-3 border-bottom pb-3 text-center fw-medium fs-4">
                Connect & Follow
              </h5>
              <ul>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faPinterest} />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="newsletter">
              <h5 class="mb-3 border-bottom pb-3 text-center fw-medium fs-4">
                Newsletter
              </h5>
              <p>
                Subscribe to our newsletter for exclusive content and all, of
                the behind the scenes details.
              </p>
              <form>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter Your Email"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    className="brand-button"
                  >
                    Submit{" "}
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      className="ms-2"
                    />
                  </Button>
                </InputGroup>
              </form>
            </div>
          </div>
          <div className="col-lg-8 mt-4 mt-lg-0">
            <p>
              <b>
                Web designing in a powerful way of just not an only professions,
                however, in a passion for our Company. We have to a tendency to
                believe the idea that smart looking of any websitet in on
                visitors.Web designing in a powerful way of just not an only
                profession
              </b>
            </p>
            <h2>Introduction</h2>
            <p>
              In the ever-evolving landscape of web design, trends come and go,
              but some leave a lasting impact. One such trend that has been
              gaining momentum in recent years is minimalism. With its focus on
              simplicity, clean lines, and usability, minimalism is
              revolutionizing the way websites are designed and experienced by
              users.
            </p>
            <img
              src={Service1}
              alt="Service Image"
              className="img-fluid img-post"
            />
            <h2>The Rise of Minimalism</h2>
            <p>
              Gone are the days of cluttered web pages filled with flashy
              graphics and overwhelming content. Instead, designers are
              embracing the less-is-more approach, opting for clean layouts,
              ample white space, and subtle typography. This shift towards
              minimalism is driven by a desire for clarity and ease of
              navigation, as well as a recognition of the importance of mobile
              responsiveness.
            </p>
            <h2>Key Elements of Minimalist Design</h2>
            <p>
              Minimalist websites often feature simple color schemes, with a
              limited palette of muted tones or monochromatic hues. This allows
              for a more cohesive and harmonious visual experience, without
              distracting users with bright colors or busy patterns. In terms of
              layout, minimalism favors asymmetry and grid-based structures,
              creating a sense of balance and order while still allowing for
              flexibility and creativity.
            </p>
            <blockquote class="quote">
              <p>
                I am truly inspired by the creative potential that the "Donate
                Your Design" initiative holds.
              </p>
              <p>
                <cite>SHANE DOE</cite>
              </p>
            </blockquote>
            <h2>Markets Planning</h2>
            <p>
              Market planning is the process of defining your target audience,
              identifying opportunities and challenges, and formulating
              strategies to achieve your business goals. It's a proactive
              approach that helps businesses anticipate market shifts, adapt to
              changing customer preferences, and stay ahead of competitors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
