import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 underline underline-heading texts">IOT Module</h3>
        <p className="fs-5 texts mt-4">
          Unlock the power of real-time data monitoring and control with IoT
          Module – your all-in-one solution for efficient and reliable
          industrial operations. Designed to seamlessly integrate into your
          existing infrastructure, IoT Module empowers businesses to optimize
          processes, reduce costs, and drive performance through actionable
          insights.
        </p>
      </div>
    </React.Fragment>
  );
};
