import React from "react";
import { Hero } from "./Hero";
import "../../styles/scss/blog_details.scss";
import { BlogContent } from "./BlogContent";
import { CommentForm } from "./CommentForm";

export const BlogDetails = () => {
  return (
    <React.Fragment>
      <div className="blog-detail-page">
        <Hero />
        <BlogContent />
        <CommentForm />
      </div>
    </React.Fragment>
  );
};
