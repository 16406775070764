import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const [displayText, setDisplayText] = useState("");
  const textOptions = {
    Features:
      "The Standard Interfacing Module facilitates seamless communication between industrial devices, converting TTL to MODBUS (RS485) and RS232, and vice versa. It supports RS232 to MODBUS (RS232/485) conversion, alongside digital and analog I/O modules with MODBUS interfaces, streamlining data acquisition and control in diverse PLC environments.",
    Industries:
      "The Standard Interfacing Module caters to a wide range of industries including manufacturing, automation, energy, and infrastructure. From integrating legacy systems with modern PLCs to facilitating seamless communication between different devices, this module enhances operational efficiency and flexibility across diverse industrial environments, ensuring smooth data exchange and control. ",
  };

  const changeText = (text) => {
    setDisplayText(textOptions[text]);
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"]);
  }, []);
  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText === textOptions[option]
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }} // Add this style to remove rounded border
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
        </div>
      </div>
    </React.Fragment>
  );
};
