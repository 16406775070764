import React, { useState, useEffect } from "react";
import imagex from "../../assets/images/leadership/leadership-hero.jpg";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Set loaded to true after a short delay to trigger the transition
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="common-hero-style hero-section position-relative py-5" style={{backgroundImage: `linear-gradient(to top,rgba(0,0,0,1), rgba(225,225,225,0.3)),url(${imagex})`, backgroundSize: loaded ? '110%' : '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat',}}>
      <div className="black-layer position-absolute top-0 start-0 end-0 bottom-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}></div>
      <div className="container">
        <div className="row d-flex position-relative">
          <div className="col-12 col-md-8 py-5">
            <div className="hero-text-section position-relative z-index-1">
              <h3 className="text-about text-white">Unlocking Innovation, Empowering Success</h3>
              <h1 className="hero-title text-white">Meet Our Leadership Team</h1>
              <p className="hero-p text-white">Welcome to Visves - where we cultivate innovation and guide you towards success. Our dynamic leadership team is dedicated to driving excellence and fostering growth in every endeavor. Join us on the journey to innovation-driven success!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
