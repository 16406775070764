import React from "react";
import clientLogo from "../../assets/images/products/clientsLogo.png";
import client1 from "../../assets/images/client/client1.png";
import client2 from "../../assets/images/client/client2.png";
import client3 from "../../assets/images/client/client3.png";
import client4 from "../../assets/images/client/client4.png";
import client5 from "../../assets/images/client/client5.png";
import client6 from "../../assets/images/client/client6.png";
export const Clients = () => {
  return (
    <React.Fragment>
      <div className="clients-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client1} alt="client 1" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client2} alt="client 2" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client3} alt="client 3" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client4} alt="client 4" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client5} alt="client 5" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client6} alt="client 6" />
            </div>
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
};
