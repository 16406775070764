import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 underline underline-heading texts">Sensor Module</h3>
        <p className="fs-5 texts mt-4">
          Sensor Module offers a comprehensive solution for industrial data
          acquisition and control, integrating precise physical parameter
          measurement, voltage and current sensing, timer functionality, and
          customizable IoT sensors. With seamless interfacing protocols and
          advanced features, Sensor Module empowers businesses to optimize
          processes, enhance efficiency, and drive performance.
        </p>
      </div>
    </React.Fragment>
  );
};
