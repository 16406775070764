import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import calendorIcon from "../../assets/images/news/calenderIcon.svg";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
import Service2 from "../../assets/images/serviceimg/control-panel.jpg";
import Service3 from "../../assets/images/serviceimg/lab-equipment.jpg";
import Image1 from "../../assets/images/news/image1.jpg";
import Image2 from "../../assets/images/news/image2.jpg";
import Image3 from "../../assets/images/news/image3.jpg";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

export const News = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = 120; // Total number of items
  const itemsPerPage = 40; // Items per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // You can perform any actions here when the page changes, such as fetching data for the new page
  };
  return (
    <React.Fragment>
      <div className="news-blog-section py-5">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-8">
              <div className="news-column">
                <div className="blog-post-section">
                  <div className="blog-image">
                    <img
                      src={Service1}
                      alt="Service Image"
                      className="img-fluid img-blog"
                    />
                    <div className="post-tag">
                      <div className="post-date">31 December,2023</div>
                      <div className="web-design">
                        <span>Web Design</span>
                      </div>
                      <div className="post-comment">
                        <span>Comments (05)</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 fs-3 fw-medium underline underline-heading texts text-justify">
                    Simplify IT for Enhanced{" "}
                  </div>
                  <div className="blog-read mb-4">
                    Web designing in a powerful way of just not an only
                    professions, however, in a passion for our Company. We have
                    to a tendency to believe the idea that smart looking of any
                    websitet in on visitors.Web designing in a powerful way of
                    just not an only profession
                  </div>
                  <div className="blog-read-more">
                    <Link to="/news-details" className="btn brand-button">
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="blog-post-section">
                  <div className="blog-image">
                    <img
                      src={Service2}
                      alt="Service Image"
                      className="img-fluid img-blog"
                    />
                    <div className="post-tag">
                      <div className="post-date">31 December,2023</div>
                      <div className="web-design">
                        <span>Web Design</span>
                      </div>
                      <div className="post-comment">
                        <span>Comments (05)</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 fs-3 fw-medium underline underline-heading texts text-justify">
                    Simplify IT for Enhanced{" "}
                  </div>
                  <div className="blog-read mb-4">
                    Web designing in a powerful way of just not an only
                    professions, however, in a passion for our Company. We have
                    to a tendency to believe the idea that smart looking of any
                    websitet in on visitors.Web designing in a powerful way of
                    just not an only profession
                  </div>
                  <div className="blog-read-more">
                    <Link to="/news-details" className="btn brand-button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="news-advance-search px-lg-4 p-0">
                <div className="search-advance mb-4">
                  <div className="advance-search-title">Search Here</div>
                  <hr className="divider" />
                  <div className="search-input">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="colFormLabelLg"
                      placeholder="Type here..."
                      onChange={(e) =>
                        console.log("value search", e.target.value)
                      }
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="magnifying-glass"
                    />
                  </div>
                </div>

                <div className="search-advance mb-4">
                  <div className="advance-search-title">category</div>
                  <hr className="divider" />
                  <div className="category-list">
                    <Link to="#" className="cat-item text-decoration-none">
                      Cleaning
                    </Link>
                    <Link to="#" className="cat-item text-decoration-none">
                      Cloud Solution
                    </Link>
                    <Link to="#" className="cat-item text-decoration-none">
                      Free Service
                    </Link>
                    <Link to="#" className="cat-item text-decoration-none">
                      Cleaner
                    </Link>
                    <Link to="#" className="cat-item text-decoration-none">
                      SEO Marketing
                    </Link>
                    <Link to="#" className="cat-item text-decoration-none">
                      CPA Marketing
                    </Link>
                  </div>
                </div>

                <div className="search-advance mb-4">
                  <div className="advance-search-title">Popular Post</div>
                  <hr className="divider" />
                  <div className="popular-post-list">
                    <div className="post">
                      <div className="post-img">
                        <img
                          src={Image1}
                          alt="Post Image"
                          className="img-post-news img-fluid"
                        />
                      </div>
                      <div className="post-date">
                        <div className="date">
                          <span className="date-img">
                            <img
                              src={calendorIcon}
                              className="img-fluid img-calender"
                            />
                          </span>
                          <span>October 19, 2023</span>
                        </div>
                        <div className="post-heading">
                          <Link to="#">
                            Connect Innovate Succeed the design
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="post">
                      <div className="post-img">
                        <img
                          src={Image2}
                          alt="Post Image"
                          className="img-post-news img-fluid"
                        />
                      </div>
                      <div className="post-date">
                        <div className="date">
                          <span className="date-img">
                            <img
                              src={calendorIcon}
                              className="img-fluid img-calender"
                            />
                          </span>
                          <span>October 19, 2023</span>
                        </div>
                        <div className="post-heading">
                          <Link to="#">
                            Elevate Your Business with IT the most
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="post">
                      <div className="post-img">
                        <img
                          src={Image3}
                          alt="Post Image"
                          className="img-post-news img-fluid"
                        />
                      </div>
                      <div className="post-date">
                        <div className="date">
                          <span className="date-img">
                            <img
                              src={calendorIcon}
                              className="img-fluid img-calender"
                            />
                          </span>
                          <span>October 19, 2023</span>
                        </div>
                        <div className="post-heading">
                          <Link to="#">
                            Simplify IT for Enhanced Productivity
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="search-advance mb-4">
                  <div className="advance-search-title">Popular Tag</div>
                  <hr className="divider" />
                  <div className="tag-list">
                    <Link to="#" className="tag-item text-decoration-none">
                      TechPros
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      NetWorks
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      CyberSafe
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      Web
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      InnovIT
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      TechSavvy
                    </Link>
                    <Link to="#" className="tag-item text-decoration-none">
                      SoftwareMasters
                    </Link>
                  </div>
                </div>

                <div className="search-advance mb-4">
                  <div className="advance-search-title">Newsletter</div>
                  <hr className="divider" />
                  <div className="words mb-2 fw-medium">
                    Subscrie to get more help and information
                  </div>
                  <div class="search-input">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="colFormLabelLg"
                      placeholder="Enter Your Email"
                      onChange={(e) =>
                        console.log("value search", e.target.value)
                      }
                    />
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="magnifying-glass"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination-row">
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      </div>
    </React.Fragment>
  );
};
