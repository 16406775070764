import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import image1 from "../../assets/images/gallery/image1.jpg";
import image2 from "../../assets/images/gallery/image2.jpg";
import image3 from "../../assets/images/gallery/image3.jpg";
import image4 from "../../assets/images/gallery/image4.jpg";
import image5 from "../../assets/images/gallery/image5.jpg";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

// Import other images as needed

// { id: 3, src: image3 },
//     { id: 4, src: image4 },
//     { id: 6, src: image5 },
//     { id: 7, src: image1 },
//     { id: 8, src: image2 },
//     { id: 9, src: image3 },
//     { id: 10, src: image4 },
//     { id: 11 src: image5 },
export const GalleryImages = () => {
  const [show, setShow] = useState(null);
  const handleClose = () => setShow(null);
  const handleShow = (id) => setShow(id);

  const ImageAsModal = ({ src, show, handleClose }) => {
    console.log("clicked with", src, show);
    return (
      <Modal show={show} onHide={handleClose} className="gallery-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image src={src} fluid />
          {/* helllooo */}
        </Modal.Body>
      </Modal>
    );
  };
  const [ImgclassName, setImgClassName] = useState(
    "img-fluid w-100 gallery-image"
  );
  const itemsPerPage = 8;
  let v_photos = [
    { id: 8, src: image1 },
    { id: 9, src: image2 },
    { id: 10, src: image3 },
    { id: 11, src: image4 },
    { id: 12, src: image5 },
    { id: 13, src: image1 },
    { id: 14, src: image2 },
    { id: 15, src: image3 },
    { id: 16, src: image4 },
  ];
  let products_photos = [
    { id: 3, src: image5 },
    { id: 17, src: image4 },
    { id: 18, src: image3 },
    { id: 19, src: image2 },
    { id: 20, src: image1 },
    { id: 21, src: image1 },
    { id: 22, src: image2 },
    { id: 23, src: image3 },
    { id: 24, src: image4 },
  ];
  const [activePage, setActivePage] = useState(0);
  const [activeButton, setActiveButton] = useState("all");
  const [dropdownText, setDropdownText] = useState("Select Category");
  const [allPhotos, setAllPhotos] = useState([...v_photos, ...products_photos]);

  const totalPages = Math.ceil(allPhotos.length / itemsPerPage);

  const handleAllPhotosClick = () => {
    setActiveButton("all");
    setActivePage(0);
    setDropdownText("All Photos");
    setAllPhotos([...v_photos, ...products_photos]);
  };

  const handleVivesOfficeClick = () => {
    setActiveButton("vivesOffice");
    setActivePage(0);
    setDropdownText("Vives Office");
    setAllPhotos([...v_photos]);
  };

  const handleProductsClick = () => {
    setActiveButton("products");
    setActivePage(0);
    setDropdownText("Products");
    setAllPhotos([...products_photos]);
  };

  const handlePreviousClick = () => {
    if (activePage > 0) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextClick = () => {
    if (
      activePage < totalPages - 1 &&
      allPhotos.length > (activePage + 1) * itemsPerPage
    ) {
      setActivePage(activePage + 1);
    }
  };

  let slicedphotos = allPhotos?.slice(
    activePage * itemsPerPage,
    (activePage + 1) * itemsPerPage
  );

  return (
    <>
      <div className="gallery-section">
        <div className="container mt-5">
          <div className="row justify-content-center mb-5">
            <div className="col-md-12 d-none d-md-flex justify-content-center">
              <button
                className={`btn ${
                  activeButton === "all" ? "btn-primary" : "btn-secondary"
                }`}
                onClick={handleAllPhotosClick}
              >
                All Photos
              </button>
              <button
                className={`btn ${
                  activeButton === "vivesOffice"
                    ? "btn-primary"
                    : "btn-secondary"
                }`}
                onClick={handleVivesOfficeClick}
              >
                Vives Office
              </button>
              <button
                className={`btn ${
                  activeButton === "products" ? "btn-primary" : "btn-secondary"
                }`}
                onClick={handleProductsClick}
              >
                Products
              </button>
            </div>
            <div className="col-lg-12 d-md-none d-flex justify-content-center">
              <div className="btn-group dropleft">
                <button
                  type="button"
                  className={`rounded-pill btn ${
                    activeButton === "all" ? "btn-primary" : "btn-secondary"
                  } dropdown-toggle`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {dropdownText}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAllPhotosClick();
                      }}
                    >
                      All photos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleVivesOfficeClick();
                      }}
                    >
                      Vives Office
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProductsClick();
                      }}
                    >
                      Products
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {slicedphotos
              // .slice(activePage * itemsPerPage, (activePage + 1) * itemsPerPage)
              .map((photo) => {
                return (
                  <div
                    key={photo.id}
                    className="col-lg-3 col-md-4 col-sm-6 mb-4"
                  >
                    <div className="gallery-image-box">
                      <img
                        src={photo.src}
                        className={ImgclassName}
                        alt={`Photo ${photo.id}`}
                        onClick={() => handleShow(photo?.id)}
                      />
                    </div>
                    {show === photo?.id && (
                      <ImageAsModal
                        src={photo.src}
                        show={true}
                        handleClose={handleClose}
                      />
                    )}
                    {/* <Image src={photo.src} alt={`Photo ${photo.id}`} className="img-fluid w-100 gallery-image" width="250" preview /> */}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="container">
          <hr className="mt-5"></hr>
          <div>
            <div className="d-flex justify-content-between">
              <button
                className="prev-next-color"
                onClick={handlePreviousClick}
                disabled={activePage === 0}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  className="pe-3"
                  icon={faCircleArrowLeft}
                  style={{
                    "--fa-primary-color": "#351ee0",
                    "--fa-secondary-color": "#f9f9f9",
                  }}
                />
                Previous
              </button>
              <button
                className="prev-next-color"
                onClick={handleNextClick}
                disabled={
                  activePage === totalPages - 1 ||
                  allPhotos.length <= (activePage + 1) * itemsPerPage
                }
                style={{ cursor: "pointer" }}
              >
                Next
                <FontAwesomeIcon className="ps-3" icon={faCircleArrowRight} />
              </button>
            </div>
            <hr className="mb-5"></hr>
          </div>
        </div>
      </div>
    </>
  );
};
