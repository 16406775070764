import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 underline underline-heading texts">Standard Interfacing Module</h3>
        <p className="fs-5 texts mt-4">
        Introducing the Standard Interfacing Module, a versatile solution facilitating seamless communication between various industrial devices. This compact module enables effortless conversion between TTL, MODBUS (RS485), RS232, and digital/analog signals, ensuring interoperability and efficiency across diverse PLC systems.
        </p>
      </div>
    </React.Fragment>
  );
};
