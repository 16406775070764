import React from "react";
import blogImg1 from "../../assets/images/products/sensorModule.jpg";
import blogImg2 from "../../assets/images/products/IOTModule.jpg";
import blogImg3 from "../../assets/images/products/standardInterfacingModule.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Blog = () => {
  return (
    <React.Fragment>
      <div className="blog-section py-5 mb-5 border-bottom">
        <div className="container">
          <div className="row blog-row">
            <div className="col-12 col-lg-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg1}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Sensor Module
                  </h5>
                  <p className="card-text blog-card-text">
                    Sensor Module offers a comprehensive solution for industrial
                    data acquisition and control, integrating...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/sensor-module">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg2}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    IOT Module
                  </h5>
                  <p className="card-text blog-card-text">
                    Unlock the power of real-time data monitoring and control
                    with IoT Module – your all-in-one solution for...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/IOT-module">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card blog-card">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg3}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Standard Interfacing Module
                  </h5>
                  <p className="card-text blog-card-text">
                    Introducing the Standard Interfacing Module, a versatile
                    solution facilitating seamless communication between various
                    industrial...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/standard-interfacing-module">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
