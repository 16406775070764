import React from "react";
import invertStart from "../../assets/images/aboutusImages/inverted_comma_start.svg";
import invertStop from "../../assets/images/aboutusImages/inverted_comma_stop.svg";
import director from "../../assets/images/aboutusImages/Director-image.jpg"
import vision from "../../assets/images/aboutusImages/vision.png"
import mission from "../../assets/images/aboutusImages/mission.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

export const DirectorMessage = () => {
  return (
    <React.Fragment>
      <div className="director-section">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="director-info text-center px-0 px-lg-5">
                <img src={director} alt="Director" className="director-img shadow rounded-circle mt-3" />
                <h2 className="mt-3 underline-heading underline-heading-dark">Director's Message</h2>
                <p className="mt-3">
                  We, Visves believe in giving a customized solution to our client & customer satisfaction is our utmost priority. Whenever we are assigned any task, our team gets involved into it with high sincerity & they make sure it is completed within stipulated guidelines. It does not stop them to innovate & come up with creative solutions, which most of the time, is acceptable.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="px-0 px-md-5">
                <div className="text-center vision-mission mb-5 py-4 lg-underline-heading underline-heading underline-heading-dark mt-4 mt-lg-0">
                  <div className="align-center d-block d-md-flex gap-3">
                    <img src={vision} alt="Vision Image" />
                    <div>
                      <div className="column d-block d-lg-flex align-items-center mission">
                        <h3>Vision</h3>
                      </div>
                      <div className="column">
                        <p className="mt-2">
                        To become a company with global presence providing best technology products and customer focused innovative solutions.  </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center vision-mission py-4 lg-underline-heading underline-heading underline-heading-dark mt-4 mt-lg-0">
                  <div className="align-center d-block d-md-flex gap-3">
                    <img src={mission} alt="Vision Image" />
                    <div>
                      <div className="column d-block d-lg-flex align-items-center mission">
                        <h3>Mission</h3>
                      </div>
                      <div className="column text-center">
                        <p className="mt-2">
                          Our mission is to improve the quality of life by making the world more productive and sustainable. We are committed to enabling the next generation of smart manufacturing. We are dedicated to delivering value to our customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>









    </React.Fragment>
  );
};
