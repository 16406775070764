import React from "react";
import imagex from "../../assets/images/solutions/solutions_hero.jpg";
export const Hero = () => {
  return (
    <React.Fragment>
      <div
        className="common-hero-style hero-section position-relative py-0 py-lg-5"
        style={{
          backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${imagex})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="black-layer position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        ></div>
        <div className="container">
          <div className="row d-flex position-relative align-items-center justify-content-center">
            <div className="col-12 col-lg-8 py-5">
              <div className="hero-text-section position-relative z-index-1">
                <h3 className="text-about text-white">
                  Welcome to Our Tailored Solutions
                </h3>
                <h1 className="hero-title text-white">At Visves Automation,</h1>
                <p className="hero-p text-white">
                we specialize in providing innovative solutions
                  tailored to meet the unique needs of various industries. Our
                  commitment to excellence drives us to deliver top-notch products
                  and services that enhance efficiency, reliability, and
                  performance across diverse sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </React.Fragment>
  );
};
