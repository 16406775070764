import React from "react";
import visonLeftImage from "../../assets/images/vision_home_left.svg";
import img_ from "../../assets/images/img_.svg";
import { useNavigate } from "react-router-dom";
import TechnicalServices from "../../assets/images/technical services.jpg";
import OperationAndMaintenance from "../../assets/images/operation and maintenance.jpg";
import LabEquipent from "../../assets/images/lab equipment.jpg";
import PlcScadaHmi from "../../assets/images/plc sacada.jpg";
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useGSAP } from '@gsap/react';
// import { useRef } from 'react';

// gsap.registerPlugin(useGSAP, ScrollTrigger);

export const Service = () => {

  // const main = useRef();

  // useGSAP(
  //   () => {
  //     const boxes = gsap.utils.toArray('.box');
  //     boxes.forEach((box) => {
  //       gsap.to(box, {
  //         x: 50,
  //         scrollTrigger: {
  //           trigger: box,
  //           start: 'bottom bottom',
  //           end: 'top 0%',
  //           scrub: true,
  //         },
  //       });
  //     });
  //   },
  //   { scope: main }
  // );

  const navigate = useNavigate();
  return (
    <>
      <div className="service-section">
        <div className="container">
          <div className="service-text-section">
            <div className="">
              <div className="text-center">
                <h5 className="text-main">SERVICES</h5>
                <h2 className="fs-2 underline underline-heading texts text-justify">We Provide</h2>
              </div>
              <div className="service-info row justify-content-between row-gap-4 mt-4 pt-5 p-0">
                <div className="col-12 col-lg-4 box">
                  <div className="custom-card h-100 mt-5 mt-lg-0">
                    <div className="card-body h-100 border p-4">
                    <img src={LabEquipent} alt="Icon" className="vector-service ms-5" />
                      <h5 className="card-title-text">
                        LAB EQUIPMENTS & CALIBRATION
                      </h5>
                      <p className="card-body-text">
                        Our company was engaged in the manufacturing of Lab
                        equipments and Interface Boards. With the efforts from our
                        Research and Development Team, the company has expanded
                        its activities in various areas like …
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 box">
                  <div className="custom-card h-100 mt-5 mt-lg-0">
                    <div className="card-body h-100 border p-4">
                    <img src={TechnicalServices} alt="Icon" className="vector-service ms-5" />
                      <h5 className="card-title-text">
                        TECHNICAL SERVICES & SUPPORTS
                      </h5>

                      <p className="card-body-text">
                        Control Panel Design/Fabrication: Industrial Electrical |
                        Electronic Enclosures | Customized. PLC / SCADA / HMI
                        Programming Services: Application and Program Development…
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 box">
                  <div className="custom-card h-100 mt-5 mt-lg-0">
                    <div className="card-body h-100 border p-4">
                    <img src={PlcScadaHmi} alt="Icon" className="vector-service ms-5" />
                      <h5 className="card-title-text">PLC/SCADA/HMI</h5>

                      <p className="card-body-text">
                        Our core competency is providing solutions for the process
                        with good capability & reliability for the process to
                        control the critical parameters. Our team is capable for
                        the high end automation from design to execution for the
                        industries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
