import React, { useState } from "react";
import productImage1 from "../../../../assets/images/gallery/image3.png";
import productImage2 from "../../../../assets/images/gallery/image2.png";
import productImage3 from "../../../../assets/images/gallery/image1.png";
import productImage4 from "../../../../assets/images/leadership/image4.png";

export const Images = () => {
    const [mainImage, setMainImage] = useState(productImage1);

    const changeMainImage = (image) => {
        setMainImage(image);
    };

    return (
        <React.Fragment>
            <div className="col-lg-6 flex-column text-center">
                <img src={mainImage} alt="Main Product Image" className="product-img img-fluid w-50" />
                <div className="row flex-row mt-4">
                    <div className="col-3">
                        <img src={productImage1} alt="Product 1" className="product-img img-fluid w-100 mw-100" onClick={() => changeMainImage(productImage1)} />
                    </div>
                    <div className="col-3">
                        <img src={productImage2} alt="Product 2" className="product-img img-fluid w-100 mw-100" onClick={() => changeMainImage(productImage2)} />
                    </div>
                    <div className="col-3">
                        <img src={productImage3} alt="Product 3" className="product-img img-fluid w-100 mw-100" onClick={() => changeMainImage(productImage3)} />
                    </div>
                    <div className="col-3">
                        <img src={productImage4} alt="Product 4" className="product-img img-fluid w-100 mw-100" onClick={() => changeMainImage(productImage4)} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
