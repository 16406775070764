import React from "react";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
export const Hero = () => {
  return (
    <div className="common-hero-style hero-section position-relative py-5"
      style={{
        background: "#f8f9fa"
      }}
    >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
                <img src={Service1} alt="Service Image" className="img-fluid img-blog" />
            </div>
            <div className="col-lg-6">
              <div className="hero-text-section position-relative z-index-1">
                <h1 className="hero-title">Simplify IT for Enhanced</h1>
                <p className="hero-p">Web designing in a powerful way of just not an only professions, however, in a passion for our Company. We have to a tendency to believe the idea that smart looking of any websitet in on visitors.Web designing in a powerful way of just not an only profession.</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
