import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const [displayText, setDisplayText] = useState("");
  const textOptions = {
    Features:
      "Sensor Module integrates advanced features for industrial data acquisition and control, including precise physical parameter measurement and customizable voltage/current sensing with versatile interfacing protocols. Its timer module streamlines device management, while customizable IoT sensors enable insightful data collection, catering to diverse sectors for enhanced operational efficiency and performance optimization.",
    Industries:
      "Sensor Module caters to a wide range of industries including manufacturing, energy, utilities, agriculture, and infrastructure. From monitoring environmental conditions in industrial facilities to optimizing power usage in utility grids, Sensor Module delivers reliable data acquisition and control solutions tailored to diverse applications, ensuring operational efficiency and performance optimization.",
  };

  const changeText = (text) => {
    setDisplayText(textOptions[text]);
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"]);
  }, []);
  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText === textOptions[option]
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }} // Add this style to remove rounded border
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
        </div>
      </div>
    </React.Fragment>
  );
};
