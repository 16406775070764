import React from "react";
import { Hero } from "./Hero";
import "../../styles/scss/news_detail.scss";
import { NewsContent } from "./NewsContent";

export const NewsDetails = () => {
  return (
    <React.Fragment>
      <div className="news-detail-page">
        <Hero />
        <NewsContent />
      </div>
    </React.Fragment>
  );
};
