import React from "react";
import blogImg1 from "../../assets/images/products/sensorModule.jpg";
import blogImg2 from "../../assets/images/products/IOTModule.jpg";
import blogImg3 from "../../assets/images/products/standardInterfacingModule.jpg";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";

let active = 1;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

export const Bloglisting = () => {
  return (
    <div className="blog-list-wrap py-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12 text-center">
            <h2 className="fs-2 underline underline-heading texts text-justify">
              Our Largest News Blog
            </h2>
          </div>
        </div>
        <div className="row blog-row">
          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg1} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">Sensor Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Sensor Module offers a comprehensive solution for industrial
                  data acquisition and control, integrating...
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg2} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">IOT Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Unlock the power of real-time data monitoring and control with
                  IoT Module – your all-in-one solution for...
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg3} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">Standard Interfacing Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Introducing the Standard Interfacing Module, a versatile
                  solution facilitating seamless communication between various
                  industrial...
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg1} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">Sensor Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Sensor Module offers a comprehensive solution for industrial
                  data acquisition and control, integrating...
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg2} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">IOT Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Unlock the power of real-time data monitoring and control with
                  IoT Module - your all-in-one solution for...
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="card blog-card mb-4">
              <img className="card-img-top blog-card-img-top" src={blogImg3} />
              <Link to="/blog-details" class="tag">
                Web Design
              </Link>
              <div className="card-body blog-card-body">
                <small>01 JAN, 2024</small>
                <h5 className="card-title-text blog-card-title">
                  <Link to="/blog-details">Standard Interfacing Module</Link>
                </h5>
                <p className="card-text blog-card-text">
                  Introducing the Standard Interfacing Module, a versatile
                  solution facilitating seamless communication between various
                  industrial...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mt-4">
              <Pagination>{items}</Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
