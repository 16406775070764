import imageBottom from "../../assets/images/image_home_bottom.svg";
// import { useEffect, useRef } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

export const BottomImage = () => {

  // const ref = useRef();
  //   useEffect(() => {
  //       const el = ref.current;
  //       gsap.fromTo(el, { scale: 0.9 }, {
  //           scale: 1, duration: 1, scrollTrigger: {
  //               trigger: el,
  //               scrub: true,
  //           }
  //       })
  //   }, [])

  return (
    <div className="home-bottom-image-section pt-0 mt-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <img src={imageBottom} />
          </div>
        </div>
      </div>
    </div>
  );
};
