import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareInstagram,
  faSquarePinterest,
  faSquareTwitter,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios"; // Import Axios
import { Link } from "react-router-dom";

export const Hero = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3001/send-email", // Change URL if your server is running on a different port or domain
        formData
      );
      console.log(response.data);
      // Handle success, e.g., show a success message to the user
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  return (
    <div className="hero-section py-5 border-bottom">
      <div className="container">
        <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6">
            <div className="hero-text-section">
              <div className="contact-mode-section">
                <div className="contact-mobile">
                  <div className="card contact-card">
                    <div className="card-body contact-card-body">
                      <div className="contact-img">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                      <div className="contact-text">
                        <div className="contact-title">Contact</div>
                        <p className="m-0 p-0">
                          <Link to="tel:+918000301086">+91 80003 01086</Link>
                        </p>
                        <p className="m-0 p-0">
                          <Link to="tel:+918238666699">+91 82386 66699</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-email">
                  <div className="card contact-card">
                    <div className="card-body contact-card-body">
                      <div className="contact-img">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <div className="contact-text">
                        <div className="contact-title">Email</div>
                        <p className="m-0 p-0">
                          <Link to="mailto:sales@visves.com">
                            sales@visves.com
                          </Link>
                        </p>
                        <p className="m-0 p-0">
                          <Link to="mailto:info@visves.com">
                            info@visves.com
                          </Link>
                        </p>
                        <p className="m-0 p-0">
                          <Link to="mailto:support@visves.com">
                            support@visves.com
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-location">
                  <div className="card contact-card">
                    <div className="card-body contact-card-body">
                      <div className="contact-img">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>
                      <div className="contact-text">
                        <div className="contact-title">Location</div>
                        <p className="m-0 p-0">
                          G-39, Safal Sumel Business Park-6, Nr. HanumanPura
                          BRTS Bus Stand, Dudheshawar, Ahmedabad, Gujarat -
                          380015.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="social-contact">
                <ul>
                  <li className="mb-3">
                    <Link to="#" className="brand-button">
                      <FontAwesomeIcon icon={faSquareFacebook} />
                      Facebook
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link to="#" className="brand-button">
                      <FontAwesomeIcon icon={faSquareTwitter} />
                      Twitter
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link to="#" className="brand-button">
                      <FontAwesomeIcon icon={faSquareInstagram} />
                      Instagram
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link to="#" className="brand-button">
                      <FontAwesomeIcon icon={faSquarePinterest} />
                      Pinterest
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h2 className="fs-2 underline underline-heading texts">
              Get in touch
            </h2>
            <p className="hero-p">
              We can help our team of experts is on hand to answer your
              questions.
            </p>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="row form-row">
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      placeholder="Your name"
                      className="form-control form-control-lg custom-input"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email"
                      className="form-control form-control-lg"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control form-control-lg"
                      id="exampleFormControlTextarea1"
                      rows="8"
                      placeholder="Your Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn brand-button">
                      Submit Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
