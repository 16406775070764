import React from "react";
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/common/Navbar";
import { Footer } from "./components/layout/Footer";
import { Home } from "./components/pages/home";
import { About } from "./components/pages/aboutUs";
import "./components/styles/global.scss";
import { Products } from "./components/pages/products";
import { Services } from "./components/pages/services";
import { Contact } from "./components/pages/contactus";
import { Blog } from "./components/pages/blog";
import { BlogDetails } from "./components/pages/blogDetails";
import { NewsArticle } from "./components/pages/newsArticle";
import { NewsDetails } from "./components/pages/newsDetails";
import { SensorModules } from "./components/pages/products/pages/SensorModule";
import { StandardInterfacingModule } from "./components/pages/products/pages/StandardInterfacingModule";
import { MillMachinery } from "./components/pages/products/pages/MillMachinery";
import { IOTModule } from "./components/pages/products/pages/IOTModule";
import { Gallery } from "./components/pages/gallery";
import { Leadership } from "./components/pages/leadership";
import ScrollToTopButton from "./components/common/ScrollButton";
import { Carrer } from "./components/pages/carrer";
import { SolutionsByIndustry } from "./components/pages/solutionsbyindustry";
import { SolutionsByApplication } from "./components/pages/solutionbyApplication";
import { PrivacyPolicy } from "./components/pages/privacyPolicy";
import { UnderMaintenance } from "./components/pages/underMaintenance";
import ScrollToTop from "./components/ScrollToTop";

export const App = () => {
  console.log("App loaded");
  return (
    <div className="app">
      <HashRouter basename="">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route
            path="/products/machinary-module"
            element={<MillMachinery />}
          />
          <Route
            path="/solutionsbyindustry"
            element={<SolutionsByIndustry />}
          />
          <Route
            path="/solutionbyApplication"
            element={<SolutionsByApplication />}
          />
          <Route
            path="/products/standard-interfacing-module"
            element={<StandardInterfacingModule />}
          />
          <Route path="/carrer" element={<Carrer />} />
          <Route path="/products/iot-module" element={<IOTModule />} />
          <Route path="/products/sensor-module" element={<SensorModules />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<UnderMaintenance />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/news-article" element={<NewsArticle />} />
          <Route path="/news-details" element={<NewsDetails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/leadership" exact="true" element={<Leadership />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/under-maintenance" element={<UnderMaintenance />} />
          <Route path="/download" element={<UnderMaintenance />} />
        </Routes>
        <ScrollToTopButton />
        <Footer />
      </HashRouter>
    </div>
  );
};
