import React from "react";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
export const NewsContent = () => {
  return (
    <div className="news-content-wrap py-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-2">
                    <div className="post-tag">
                        <ul>
                            <li>
                                <span className="d-block">Date :</span> 31 December,2023
                            </li>
                            <li>
                                <span>category</span>Web Design
                            </li>
                            <li>
                                <span>Comments :</span>(05)
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-10">
                    <p>
                    <b>Web designing in a powerful way of just not an only professions, however, in a passion for our Company. We have to a tendency to believe the idea that smart looking of any websitet in on visitors.Web designing in a powerful way of just not an only profession</b>
                    </p>
                    <h2>Introduction</h2>
                    <p>
                    In the ever-evolving landscape of web design, trends come and go, but some leave a lasting impact. One such trend that has been gaining momentum in recent years is minimalism. With its focus on simplicity, clean lines, and usability, minimalism is revolutionizing the way websites are designed and experienced by users.
                    </p>
                    <img src={Service1} alt="Service Image" className="img-fluid img-post" />
                    <h2>The Rise of Minimalism</h2>
                    <p>Gone are the days of cluttered web pages filled with flashy graphics and overwhelming content. Instead, designers are embracing the less-is-more approach, opting for clean layouts, ample white space, and subtle typography. This shift towards minimalism is driven by a desire for clarity and ease of navigation, as well as a recognition of the importance of mobile responsiveness.</p>
                    <h2>Key Elements of Minimalist Design</h2>
                    <p>Minimalist websites often feature simple color schemes, with a limited palette of muted tones or monochromatic hues. This allows for a more cohesive and harmonious visual experience, without distracting users with bright colors or busy patterns. In terms of layout, minimalism favors asymmetry and grid-based structures, creating a sense of balance and order while still allowing for flexibility and creativity.</p>
                    <h2>Focus on User Experience</h2>
                    <p>At the heart of minimalist design is a focus on the user experience. By stripping away unnecessary elements and streamlining the interface, designers can create websites that are intuitive and easy to navigate. This not only improves usability but also enhances engagement and encourages users to spend more time on the site.</p>
                </div>
            </div>
        </div>
    </div>
  );
};
