import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ApplyForm = ({ selectedOpening, onClose, _isOpen }) => {
    const [isOpen, setIsOpen] = useState(_isOpen);
    const [formData, setFormData] = useState({
        fullName: "",
        gender: "",
        dob: "",
        address: "",
        cityDistrict: "",
        state: "",
        pincode: "",
        phoneNumber: "",
        resume: null
    });

    const handleClose = () => {
        setIsOpen(false);
        onClose();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData({ ...formData, resume: file });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
    
        try {
            // Define the URL where your server is listening
            const url = "http://localhost:3001/application";
    
            // Create form data object
            const formDataToSend = new FormData();
            formDataToSend.append("fullName", formData.fullName);
            formDataToSend.append("gender", formData.gender);
            formDataToSend.append("dob", formData.dob);
            formDataToSend.append("address", formData.address);
            formDataToSend.append("cityDistrict", formData.cityDistrict);
            formDataToSend.append("state", formData.state);
            formDataToSend.append("pincode", formData.pincode);
            formDataToSend.append("phoneNumber", formData.phoneNumber);
            formDataToSend.append("resume", formData.resume);
    
            // Send POST request to the server
            const response = await axios.post(url, formDataToSend);
            console.log("Email sent successfully:", response.data);
            // Optionally, show a success message to the user
        } catch (error) {
            console.error("Error sending email:", error);
            // Optionally, show an error message to the user
        }
    };
    

    return (
        <>
            {isOpen && (
                <div className="apply-form container">
                    <div className="row mb-3 pb-3 border-bottom flex-md-row flex-column-reverse">
                        <div className="col-lg-8 col-md-9">
                            <h2 className="text-start underline underline-heading">Apply for {selectedOpening ? selectedOpening : "Position"}</h2>
                        </div>
                        <div className="col-lg-4 col-md-3 text-right">
                            <button className="brand-button ms-auto me-0 d-block fs-3 py-0 px-3 text-end fw-bold" onClick={handleClose}><FontAwesomeIcon icon={faXmark} /></button>
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    {/* Full Name */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fullName"
                                            name="fullName"
                                            placeholder="Full Name"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="fullName">Full Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Gender */}
                                    <div className="mb-3">
                                        <label>Gender</label><br />
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="male"
                                                value="male"
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="male">Male</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="female"
                                                value="female"
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="female">Female</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="other"
                                                value="other"
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="other">Other</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Date of Birth */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="dob"
                                            name="dob"
                                            value={formData.dob}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="dob">Date of Birth</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Address */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            placeholder="Address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="address">Address</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* City/District */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cityDistrict"
                                            name="cityDistrict"
                                            placeholder="City/District"
                                            value={formData.cityDistrict}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="cityDistrict">City/District</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* State */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            name="state"
                                            placeholder="State"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="state">State</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Pincode */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pincode"
                                            name="pincode"
                                            placeholder="Pincode"
                                            value={formData.pincode}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="pincode">Pincode</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Phone Number */}
                                    <div className="form-floating mb-3">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/* Resume Upload */}
                                    <div className="mb-3">
                                        <label htmlFor="resume">Upload Resume</label><br />
                                        <input
                                            type="file"
                                            id="resume"
                                            name="resume"
                                            accept=".pdf,.doc,.docx"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/* Submit Button */}
                                    <button type="submit" className="btn brand-button">Apply Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default ApplyForm;
