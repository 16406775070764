import React from "react";
import contactMob from "../../assets/images/contact/contact.svg";
import contactEmail from "../../assets/images/contact/email.svg";
import contactLoc from "../../assets/images/contact/location.svg";
export const ContactUs = () => {
  return (
    <React.Fragment>
      <div className="contact-section py-5">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 mt-4">
              <div className="text-center mb-4">
                <h2 class="fs-2 underline underline-heading texts">Find Us on Google Maps</h2>
              </div>
              <div className="location-section">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.3847328154116!2d72.58171887441671!3d23.046352515442113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e846935293a7f%3A0xe53308b0fc10015d!2sBLOCK-E%2C%20SUMEL%20BUSINESS%20PARK-6%2C%20Dudheshwar%2C%20Ahmedabad%2C%20Gujarat%20380004!5e0!3m2!1sen!2sin!4v1711024713485!5m2!1sen!2sin" width="100%" height="500" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
