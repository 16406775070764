import React from "react";
import director from "../../assets/images/aboutusImages/Director-image.jpg"
import profile from "../../assets/images/leadership/profile.jpg"
import vaibhav from "../../assets/images/leadership/Teams/VAIBHAV  PATEL.jpg"
import ajay from "../../assets/images/leadership/Teams/AJAY  SOLANKI.jpg"
import aniket from "../../assets/images/leadership/Teams/ANIKET  PANCHAL.jpg"
import chetan from "../../assets/images/leadership/Teams/CHETAN  RAVAT.jpg"
import dhaval from "../../assets/images/leadership/Teams/DHAVAL  PRAJAPATI.jpg"
import hitesh from "../../assets/images/leadership/Teams/HITESH  PANCHAL.jpg"
import jaimin from "../../assets/images/leadership/Teams/JAIMIN PARMAR.jpg"
import meet from "../../assets/images/leadership/Teams/MEET PATEL.jpg"
import pranali from "../../assets/images/leadership/Teams/PRANALI  TRIVEDI.jpg"
import prakash from "../../assets/images/leadership/Teams/PRAKASH  DETRUJYA.jpg"
import ronak from "../../assets/images/leadership/Teams/RONAK  RAVAT.jpg"
import sachin from "../../assets/images/leadership/Teams/SACHIN PATNI.jpg"
import santosh from "../../assets/images/leadership/Teams/SANTOSH  DETRUJIYA.jpg"
import sundar from "../../assets/images/leadership/Teams/SUNDER  SOLANKI.jpg"
import tirth from "../../assets/images/leadership/Teams/tirth malvania.jpg"
import vishwash from "../../assets/images/leadership/Teams/VISHWASH  BHAVSAR.jpg"

export const Teams = () => {
  return (
    <React.Fragment>
      <div className="teams-section py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">Founders</h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. VIKASH TRIVEDI
                  </h5>
                  <p className="card-text team-card-text">
                    M.D (Industrial Expansion and Financial Affairs)
                  </p>
                  <p className="card-text team-card-text">Number:- 9998955235</p>
                  <p className="card-text team-card-text">
                    Email ID:- trivadi@visves.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={director}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. AKASHKUMAR BHATTI
                  </h5>
                  <p className="card-text team-card-text">
                    DIRECTOR (Pre-sales & Production)
                  </p>
                  <p className="card-text team-card-text">Number:- 8000301086</p>
                  <p className="card-text team-card-text">
                    Email ID:- ab@visves.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. BHIKHABHAI PANCHAL
                  </h5>
                  <p className="card-text team-card-text">DIRECTOR</p>
                  <p className="card-text team-card-text">Number:- 9998955235</p>
                  <p className="card-text team-card-text">
                    Email ID:- trivadi@visves.com
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">Advisory Board of VISVES</h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    Dr. VINAY DEEP PUNETHA
                  </h5>
                  <p className="card-text team-card-text">
                    PhD, Postdoctoral: south Korea
                  </p>
                  {/* <p className="card-text team-card-text">Number:- 8000301086</p>
                  <p className="card-text team-card-text">
                    Email ID:- ab@visves.com
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. PARTH MODI</h5>
                  <p className="card-text team-card-text">
                    Technical head Education Department
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">Our Research and Development Team</h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. CHIRAG PANCHAL
                  </h5>
                  <p className="card-text team-card-text">
                    Research and Development HEAD
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. AKSHAY SANKALE
                  </h5>
                  <p className="card-text team-card-text">SR. PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={vaibhav}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. VAIBHAV PATEL
                  </h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={meet}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. MEET PATEL</h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={pranali}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    Mrs. PRANALI TRIVEDI
                  </h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={dhaval}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. DHAVAL PRAJAPATI
                  </h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">Our Team</h3>
          </div>
          <div className="row founders-team-row">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. PRATHAM MAKWANA
                  </h5>
                  <p className="card-text team-card-text">
                    OPERATIONAL INCHARGE/ SR.PRODUCTION ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={tirth}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. TIRTH MALVANIA
                  </h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={jaimin}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. JAIMIN PARMAR
                  </h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={vishwash}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. VISHWASH BHAVSAR
                  </h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={aniket}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. ANIKET PANCHAL
                  </h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={sachin}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. SACHIN PATNI</h5>
                  <p className="card-text team-card-text">SR. TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. HASMUKH PANCHAL
                  </h5>
                  <p className="card-text team-card-text">SR. TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={prakash}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. PRAKASH DETRUJIYA
                  </h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={ronak}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. RONAK RAVAT</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={santosh}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. SANTOSH DETRUJIYA
                  </h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={chetan}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. CHETAN RAVAT</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={ajay}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. AJAY SOLANKI</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={sundar}
                  alt="Card image cap"
                />
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. SUNDAR SOLANKI
                  </h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </React.Fragment>
  );
};
