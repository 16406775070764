import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
import Service2 from "../../assets/images/serviceimg/control-panel.jpg";
import Service3 from "../../assets/images/serviceimg/lab-equipment.jpg";
import Service4 from "../../assets/images/serviceimg/industrial-drives.jpg";
import Service5 from "../../assets/images/serviceimg/plc-scada.jpg";
import Service6 from "../../assets/images/serviceimg/operation-maintenance.jpg";

export const VServices = () => { 
  return (
    <React.Fragment>
      <div className="services-section py-5">
        <div className="container">
          <div>
            <h3 className="fs-2 underline underline-heading texts text-justify">Services We Provide</h3>
            <p className="pb-5">Welcome to our services page, where we pride ourselves on delivering exceptional solutions tailored to meet your needs. At Visves Advance Engineering, we offer a comprehensive range of services designed to streamline your operations and enhance your business outcomes. From cutting-edge technology solutions to expert consulting services, we are committed to providing the highest quality of support and innovation. Whether you're looking to optimize your digital presence, improve efficiency, or expand your market reach, our team of experienced professionals is here to help you every step of the way. Explore our services below and discover how we can empower your business to thrive in today's dynamic marketplace.</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service1} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>TECHNICAL SERVICES & SUPPORTS</h3>
                  <p>Control Panel Design/Fabrication: Industrial Electrical | Electronic Enclosures | Customized. PLC / SCADA / HMI Programming Services: Application and Program Development…</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service2} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>CONTROL PANELS</h3>
                  <p>Distribution panels are required in each and every industry for controlling action. We are manufacturer of Control panels & APFC / PCC Electrical panels as per customer requirements.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service3} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>LAB EQUIPMENTS & CALIBRATION</h3>
                  <p>Our company was engaged in the manufacturing of Lab equipments and Interface Boards. With the efforts from our Research and Development Team, the company has expanded its…</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service4} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>INDUSTRIAL DRIVES & CONTROL</h3>
                  <p>With the increasing demand for energy efficiency, safety, reliable connectivity and precise control, industrial drives for factory automation systems are becoming more and more …</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service5} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>PLC/SCADA/HMI</h3>
                  <p>Our core competency is providing solutions for the process with good capability & reliability for the process to control the critical parameters. Our team is capable for the high …</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service6} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>OPERATIONS AND MAINTENANCE</h3>
                  <p>Our organization provide the best Operations and Maintenance for Project. These service are being rendered on instrumentation projects, automation projects and other concerned projects…</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
